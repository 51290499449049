import React, { useContext } from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { JobPositionContext } from "context/jobPositionViewContext";
import { useSelector } from "react-redux";

import { FiTrashNoBold } from "assets/icons";
import EditAssignShift from "pages/dashboard/employeeView/assignShift/editAssignShift";

function ModalEditAssignShift({
  showRequestReplacement,
  setConfirmDeleteAssignshift,
  setTitleReplacement,
  titleReplacement,
  dataEmployees,
}) {
  const {
    shiftNameAssign,
    setValueShiftName,
    editAssignShift,
    uIdAssignShiftDetail,
    assignSelectJob,
    setShowModalEditAssign,
  } = useContext(JobPositionContext);

  const { currentRole } = useSelector((state) => state.loginSlice);

  // ${
  //   !showRequestReplacement && "hidden"
  // }
  
  return (
    <ModalDialog
      icon={[
        {
          icon: (
            <div className={`  flex gap-3 items-center mr-2`}>
              {currentRole === "Admin" && (
                <FiTrashNoBold
                  onClick={() => {
                    setConfirmDeleteAssignshift(true);
                  }}
                />
              )}
            </div>
          ),
        },
      ]}
      onClose={() => {
        setShowModalEditAssign(false);
        setTitleReplacement(false);

        // setShiftNameAssign([]);
      }}
      title={titleReplacement ? "Shift Details" : "Edit Assign Shift"}
      className={"h-[700px] w-[750px] "}
    >
      <EditAssignShift
        shiftNameAssign={shiftNameAssign}
        setValueShiftName={setValueShiftName}
        shiftData={editAssignShift}
        dataEmployee={dataEmployees}
        selectJobs={assignSelectJob}
        uIdData={uIdAssignShiftDetail}
        setShowModalEditAssign={setShowModalEditAssign}
        setTitleReplacement={setTitleReplacement}
      />
    </ModalDialog>
  );
}

export default ModalEditAssignShift;
