import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "userLogin",
  initialState: {
    isLogin: false,
    currentRole: "",
    currentUser: null,
    token: "",
  },
  reducers: {
    setLoginReducer: (state, action) => {
      state.isLogin = true;
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      // state.currentRole = action.payload.currentUser.role[0];
      state.currentRole = action.payload.currentRole;
    },
    setLogoutReducer: (state) => {
      state.isLogin = false;
      state.currentUser = null;
      state.currentRole = "";
      state.token = "";
    },
    setRoleReducer: (state) => {
      if (state.currentRole === "Admin") {
        state.currentRole = state.currentUser.role.includes("Supervisor")
          ? "Supervisor"
          : "Employee";
      } else {
        if (state.currentUser.role.includes("Admin")) {
          state.currentRole = "Admin";
        }
      }
      localStorage.setItem("currentRole", state.currentRole);
    },
  },
});

export const { setLoginReducer, setLogoutReducer, setRoleReducer } =
  loginSlice.actions;
export default loginSlice.reducer;
