export const getLocalStorage = () => {
    const payload = JSON.parse(localStorage.getItem("currentUser")) || "";
    const token = localStorage.getItem("accessToken") || "";
    const companyID = payload ? payload?.organizationID : null

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return {
        config,
        companyID
    }
}

export const capitalize_first_letter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}