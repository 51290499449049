import React from "react";
import { useGetHistoryByIdReplacement } from "services/conflickDataApi";
import RequestApproveModal from "components/requestApproveModal";

function DetailsShiftReplacement({ replacementById, setShowModalReplace }) {
  const { data: dataByIdReplacement, isLoading: loadingReplacementById } =
    useGetHistoryByIdReplacement({
      uId: replacementById,
    });

  if (loadingReplacementById) {
    return <div>Loading...</div>;
  }

  const dataByID = dataByIdReplacement.data;

  const previousUserData = dataByID.previousUserData;
  const dataReplacementEmployee = dataByID && dataByID.replacementUserData;

  const statusbyAdmin = dataByID && dataByID?.status?.byAdmin?.status;

  const statusEmployee = dataByID && dataByID?.status?.byEmployee?.status;

  //   const employeeUid = shiftData && shiftData.employee.uId;

  const shiftDetails = dataByID && dataByID.shiftData;
  const assignShift = dataByID.assignShiftData;
  const createdByAdmin = dataByID?.createdByData;
  return (
    <div>
      <RequestApproveModal
        setApproveModal={setShowModalReplace}
        assignShiftDate={assignShift.date}
        dateRequest={dataByID?.createdAt}
        shiftName={shiftDetails?.shiftName}
        locationName={previousUserData?.locationName}
        startTime={shiftDetails?.startTime}
        endTime={shiftDetails?.endTime}
        employeeName={previousUserData?.firstName}
        requestorPhoto={previousUserData?.photo}
        nameReplacement={dataReplacementEmployee?.firstName}
        replacementPhoto={dataReplacementEmployee?.photo}
        statusAproveReplacement={statusEmployee}
        statusAproveHr={statusbyAdmin}
        // onReject={handleReject}
        // onAccept={handleAccept}
        hrAdminName={createdByAdmin?.firstName}
        hrAdminPhoto={createdByAdmin?.photo}
        jobPosition={assignShift?.jobPosition}
        adminId={createdByAdmin?.uId}
        replacementUserData={dataReplacementEmployee}
      />
    </div>
  );
}

export default DetailsShiftReplacement;
