// Corrected function to convert time string to Date object with explicit parsing
function convertToTime(time) {
    const [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
        console.error(`Invalid time format: ${time}`);
        return null; // Return null if time format is invalid
    }
    // Create a new Date object set to the beginning of today, then set the hours and minutes
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set time without changing the date
    return date;
}

// Main function with detailed condition checks and logging
export function validateTimeRange(input1, input2, time1, time2) {
    // Convert input times
    const inputTime1 = convertToTime(input1);
    const inputTime2 = convertToTime(input2);
    const startTime = convertToTime(time1);
    const endTime = convertToTime(time2);

    // Check for invalid dates
    if (!inputTime1 || !inputTime2 || !startTime || !endTime) {
        console.error("One or more time conversions failed due to invalid format.");
        return false;
    }

    // Step-by-step conditions
    const condition1 = inputTime1 > startTime;
    const condition2 = inputTime1 < inputTime2;
    const condition3 = inputTime2 < endTime;
    const condition4 = inputTime2 > inputTime1;

    // Return true only if all conditions are satisfied
    return condition1 && condition2 && condition3 && condition4;
}



