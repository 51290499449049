import React, { useState, useEffect, useContext } from "react";
import {
  ModalDialog,
  ModalConfirmation,
  ShiftListBox,
} from "@bluesilodev/timhutcomponents";
import { useSelector, useDispatch } from "react-redux";
import { JobPositionContext } from "context/jobPositionViewContext";
import { useQueryClient } from "@tanstack/react-query";

import { useGetAllShift } from "services/shiftServices";
import { setLocationState } from "store/employeeViewSlice";
import { useGetAllLocation } from "services/employeeApi";
import { useDeleteShift } from "services/shiftServices";
import { alertSuccess, alertError } from "services/alert";
import { useGetAssignShift } from "services/assignShiftService";
import { useGetAllDashboard } from "services/assignShiftService";
import { setShowRequesReplacement } from "store/requestReplacementSlice";

// import ShiftListBox from "coba/shiftLists/ShiftListBox";
import { useDivRef } from "context/refProvider";
import AddAssignShift from "../employeeView/assignShift/addAssignShift";
import ModalShiftDetails from "./allModal/modalShiftDetails";
import ModalEditAssignShift from "./allModal/modalEditAssignShift";
import ModalDeleteAssignShift from "./allModal/modalDeleteAssignShift";
import { useReplacementConflick } from "context/ReplacementConflickProvider";
import FindReplacementRequest from "../employeeView/findReplacementRequest";
import ConflictOneData from "../employeeView/conflickOneData";

function JobPositionView() {
  const [modalDelete, setShowModalDelete] = useState(false);
  const [titleReplacement, setTitleReplacement] = useState();
  const [confirmDeleteAssignShift, setConfirmDeleteAssignshift] =
    useState(false);
  const queryClient = useQueryClient();

  const { currentRole } = useSelector((state) => state.loginSlice);

  const {
    // ASSIGN SHIFT
    showModalAssign,
    setShowModalAssign,
    showModalEditShift,
    locationSelect,
    setSelectLocation,
    handleShowAssign,
    handleShowShiftDetails,
    setShiftNameAssign,
    shiftNameAssign,
    setValueShiftName,
    filterByShiftName,
    assignAllData,
    assignSelectJob,
    setModalEditShift,
    shiftDetailData,
    shiftDetailId,
    // EDIT ASSIGN SHIFT
    handleEditAssignShift,
    showModalEditAssign,

    // FIND REPLACEMENT
    editAssignShift,
    uIdAssignShiftDetail,
    setShowModalEditAssign,
  } = useContext(JobPositionContext);

  const { handleConflickData, modalConflickData, onCloseModalConflick } =
    useReplacementConflick();

  const dispatch = useDispatch();

  // SET DATA SHIFT

  const { dataDate, dateRange, locationState, selectView, dataEmployees } =
    useSelector((state) => state.employeeView);

  const { showRequestReplacement } = useSelector(
    (state) => state.requestReplacement
  );

  const { remainingHeight } = useDivRef();

  const { data: dataShiftTemplate, isLoading: loadingGetAllshift } =
    useGetAllShift({
      onSuccess: () => {
        // queryClient.invalidateQueries("getAllShift");
      },
      onError: (err) => {
        console.log("something wrong", err);
      },
    });

  const {
    data: dataDashboard,
    isLoading: dashboardingLoading,
    isSuccess: successDashboard,
  } = useGetAllDashboard({
    fromDate: dateRange.fromDate.toISOString(),
    toDate: dateRange.toDate.toISOString(),
  });

  // GET ALL ASSIGN SHIFT
  const {
    data: dataAssignShift,
    isLoading: loadingAssignShift,
    refetch,
  } = useGetAssignShift();

  // GET ALL LOCATION
  const {
    data: locationData,
    isSuccess: successLocation,
    isLoading: loadingLocation,
  } = useGetAllLocation();

  // DELETE SHIFT DETAIL
  const { mutate: deleteShiftDetail, isLoading: loadingDeleteShift } =
    useDeleteShift(shiftDetailId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllShift");
        alertSuccess("Data has been Deleted");
        setShowModalDelete(false);
        setModalEditShift(false);
      },
      onError: (err) => {
        alertError("Something Wrong!");
        console.log("something wrong", err);
      },
    });

  const onConfirmDeleteShift = () => {
    deleteShiftDetail();
  };

  useEffect(() => {
    if (successDashboard && dataDashboard) {
      refetch();
    }

    if (successLocation && locationData && !loadingLocation) {
      setSelectLocation(locationData.data[0].uId);
    }
  }, [dateRange, locationData]);

  if (
    loadingGetAllshift ||
    loadingAssignShift ||
    dashboardingLoading ||
    loadingDeleteShift
  ) {
    return <div>Loading...</div>;
  }

  // SELECT LOCATION
  const selectAllLocation =
    locationData?.data?.length > 0 &&
    locationData?.data?.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  // SET LOCATION
  dispatch(setLocationState(locationSelect));

  return (
    <div>
      {/* <div className="fixed top-[50px] left-[100px] font-bold text-2xl">
        {locationSelect}
      </div> */}
      <div className="w-full">
        <ShiftListBox
          selectView={selectView}
          dataAssignShift={dataDashboard}
          dataDate={dataDate}
          shiftDataTemplate={dataShiftTemplate?.data}
          handleClickFormShift={handleShowShiftDetails}
          handleShowAssign={handleShowAssign}
          dataEmployees={dataEmployees}
          locationData={selectAllLocation}
          handleEditAssignShift={handleEditAssignShift}
          onChangeLocation={(e) => {
            setSelectLocation(e.target.value);
          }}
          locationState={locationState}
          divHeightEmployee={remainingHeight}
          handleConflickAssign={handleConflickData}
          roleUser={currentRole === "Admin"}
        />
      </div>
      {/* MODAL ADD ASSIGN SHIFT */}
      {showModalAssign && (
        <ModalDialog
          onClose={() => {
            setShowModalAssign(false);
            setShiftNameAssign([]);
          }}
          title={"Assign Shift"}
          className={"h-[700px] w-[750px] "}
        >
          <AddAssignShift
            shiftNameAssign={shiftNameAssign}
            setValueShiftName={setValueShiftName}
            filterByShiftName={filterByShiftName}
            dataEmployee={dataEmployees}
            dataAssign={assignAllData}
            selectJobs={assignSelectJob}
            setShowModalAssign={setShowModalAssign}
          />
        </ModalDialog>
      )}

      {/* MODAL EDIT SHIFT DETAIL */}
      {showModalEditShift && (
        <ModalShiftDetails
          setModalEditShift={setModalEditShift}
          setShowModalDelete={setShowModalDelete}
          shiftDetailData={shiftDetailData}
          shiftDetailId={shiftDetailId}
        />
      )}

      {/* MODAL DELETE SHIFT DETAIL */}
      {modalDelete && (
        <ModalConfirmation
          title={"Delete Shift"}
          headMessage={"This is Will be Delete Shift"}
          onClose={() => {
            setShowModalDelete(false);
          }}
          onConfirm={onConfirmDeleteShift}
        />
      )}

      {/* MODAL EDIT ASSIGN SHIFT */}
      {showModalEditAssign && !showRequestReplacement && (
        <ModalEditAssignShift
          dataEmployees={dataEmployees}
          setTitleReplacement={setTitleReplacement}
          titleReplacement={titleReplacement}
          setConfirmDeleteAssignshift={setConfirmDeleteAssignshift}
        />
      )}

      {/* MODAL DELETE ASSIGN SHIFT DETAIL */}
      {confirmDeleteAssignShift && (
        <ModalDeleteAssignShift
          setConfirmDeleteAssignshift={setConfirmDeleteAssignshift}
        />
      )}
      {/* SHOW MODAL FIND REPLACEMENT */}
      {showRequestReplacement && (
        <ModalDialog
          title={"Find Replacement"}
          onClose={() => {
            dispatch(setShowRequesReplacement(false));
          }}
          className={"h-[700px] w-[750px] "}
        >
          <FindReplacementRequest
            shiftData={editAssignShift}
            uIdShift={uIdAssignShiftDetail}
            setShowModalEditAssign={setShowModalEditAssign}
          />
        </ModalDialog>
      )}

      {/* REPLACEMENT CONFLICK DATA */}
      {modalConflickData && (
        <ModalDialog
          title={"Conflict"}
          className={"w-[730px] h-[700px] "}
          onClose={onCloseModalConflick}
        >
          <ConflictOneData />
        </ModalDialog>
      )}
    </div>
  );
}

export default JobPositionView;
