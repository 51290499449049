import { createContext, useState } from "react";

export const JobPositionContext = createContext();

// export const useJobPositionView = () => useContext(JobPositionContext);

function JobPositionViewProvider({ children, valuesDate }) {
  const [shiftDetailData, setShiftDetailData] = useState();
  const [shiftDetailId, setShiftDetailID] = useState();
  const [showModalEditShift, setModalEditShift] = useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [locationSelect, setSelectLocation] = useState("");

  // ASSIGN STATE
  const [assignAllData, setAssignData] = useState({});
  const [shiftNameAssign, setShiftNameAssign] = useState([]);
  const [assignSelectJob, setAssignJob] = useState();
  const [valueShiftName, setValueShiftName] = useState();

  // STATE EDIT ASSIGN SHIFT
  const [editAssignShift, setEditAssignShift] = useState([]);
  const [uIdAssignShiftDetail, setUIdAssignShiftDetail] = useState();
  const [showModalEditAssign, setShowModalEditAssign] = useState(false);

  // FILTER DATA BY SHIFTNAME
  const filterByShiftName =
    assignAllData.shift &&
    assignAllData.shift.length > 0 &&
    assignAllData.shift.filter((item) => {
      return valueShiftName && valueShiftName === item.uId;
    });

  let statusBorder = false;

  // HANDLE SHOW SHIFT DETAILS
  const handleShowShiftDetails = (data, idx) => {
    // console.log("DATA SHIFT DETAIL : ", data);

    setShiftDetailID(idx);
    setShiftDetailData(data);

    setModalEditShift(true);
  };

  // HANDLE SHOW ASSIGN
  const handleShowAssign = (data, idx) => {
    setAssignData(data);

    // console.log("DATA : ", data);
    // setAssignID(idx);

    // JOB POSITION FROM EMPLOYEE INCLUDE ON SHIFT
    const jobPositionSelect =
      data &&
      data.shift.length > 0 &&
      data.shift.flatMap((select) => {
        return (
          select.employees.length > 0 &&
          select.employees.map((selectJob) => {
            return {
              label: selectJob.jobPosition,
              value: selectJob.jobPosition,
            };
          })
        );
      });

    setAssignJob(jobPositionSelect);

    // shift name input select
    const shiftName =
      data &&
      // data.shift.length > 0 &&
      data.shift.map((item) => {
        return {
          label: item.shiftName,
          value: item.uId,
        };
      });

    setShiftNameAssign(shiftName);

    setShowModalAssign(true);
  };

  // HANDLE EDIT ASSIGN SHIFT
  const handleEditAssignShift = (data, idx) => {
    // console.log("DATA EDIT  : ", data);
    // console.log("EDIT ID : ", idx);
    console.log("DATA assign : ", data);

    setUIdAssignShiftDetail(idx);
    setEditAssignShift(data);

    setShowModalEditAssign(true);
  };

  const contextVal = {
    // STATE ASSIGN SHIFT
    setShiftNameAssign,
    setShowModalAssign,
    setValueShiftName,
    valueShiftName,
    filterByShiftName,
    assignAllData,
    assignSelectJob,
    shiftNameAssign,

    // STATE EDIT ASSIGN SHIFT
    statusBorder,
    handleShowAssign,
    handleShowShiftDetails,
    showModalEditShift,
    locationSelect,
    setSelectLocation,
    showModalAssign,
    setModalEditShift,
    shiftDetailData,
    shiftDetailId,

    // EDIT ASSIGN SHIFT
    handleEditAssignShift,
    editAssignShift,
    uIdAssignShiftDetail,
    showModalEditAssign,
    setShowModalEditAssign,
  };

  return (
    <JobPositionContext.Provider value={contextVal}>
      {children}
    </JobPositionContext.Provider>
  );
}

export default JobPositionViewProvider;
