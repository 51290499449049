import React from "react";

function InputCheckboxCustom({
  id,
  value,
  onChange,
  checkedValue,
  title,
  error,
  disabled,
  ...props
}) {
  const handleChange = (event) => {
    if (!disabled) {
      onChange(event, checkedValue);
    }
  };

  return (
    <label htmlFor={id} className="relative cursor-pointer">
      <input
        id={id}
        type="checkbox"
        className={`outline-none rounded-full border-[1px] ${
          disabled && "bg-gray-200"
        } ${
          error ? "border-red-500" : "border-black"
        } cursor-pointer w-[58px] h-[58px]  flex justify-center items-center select-none appearance-none`}
        checked={!disabled && value.includes(checkedValue)}
        onChange={handleChange}
        {...props}
      />

      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-xl select-none">
        {title}
      </div>

      <div
        className={`absolute top-0 left-0 bg-orange-500 w-full h-full rounded-full text-white flex justify-center items-center cursor-pointer select-none ${
          value.includes(checkedValue) ? "block" : "hidden"
        }`}
      >
        <h1 className="text-xl">{title}</h1>
      </div>
    </label>
  );
}

export default InputCheckboxCustom;
