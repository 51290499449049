import React from "react";

function CancelXIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" fill="#B83C3C" />
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="#FDFDFD" />
      <path
        d="M16.2354 8.26465L7.76477 16.7352"
        stroke="#FDFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76476 8.26465L16.2354 16.7352"
        stroke="#FDFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CancelXIcon;
