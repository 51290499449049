import React from "react";

function PendingIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" fill="#A3A3AB" />
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="#FDFDFD" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5293 12.5001C18.5293 16.1065 15.6062 19.0295 11.9999 19.0295C8.39352 19.0295 5.47046 16.1065 5.47046 12.5001C5.47046 8.89376 8.39352 5.9707 11.9999 5.9707C15.6062 5.9707 18.5293 8.89376 18.5293 12.5001Z"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4222 14.5773L11.761 12.9898V9.56836"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PendingIcon;
