import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import employeeViewReducer from "./employeeViewSlice";
import jobPositionViewReducer from "./jobPositionView";

import loginSlice from "./userLoginSlice";
import requestReplacementSlice from "./requestReplacementSlice";
import { CommonApi } from "./commonApi";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  employeeView: employeeViewReducer,
  loginSlice,
  requestReplacement: requestReplacementSlice,
  jobPositionView: jobPositionViewReducer,
  [CommonApi.reducerPath]: CommonApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(CommonApi.middleware);
  },
});

export const persistor = persistStore(store);
