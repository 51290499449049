import React, { useState } from "react";
import {
  ModalDialog,
  InputSelect,
  InputDate,
  Button,
} from "@bluesilodev/timhutcomponents";

import { useGetAllLocation, useGetAllJobPosition } from "services/employeeApi";
import { useGetAllDashboard } from "services/assignShiftService";

function ModalDownloadExcel({ setShowModalDowload, listEmployee }) {
  const [selectFilterBy, setSelectFilterBy] = useState("Today");
  const [selectLocation, setSelectLocation] = useState("");
  const [selectJobPosition, setSelectJobPosition] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [selectEmployee, setSelectEmployee] = useState("All Employee");

  const onCLoseModal = () => {
    setShowModalDowload(false);
  };

  const { data: locationData, isLoading: locationLoading } =
    useGetAllLocation();

  const { data: allJobPosition, isLoading: loadingJobPosition } =
    useGetAllJobPosition();

  const { data: dataDashboard, isLoading: loadingDashboard } =
    useGetAllDashboard();

  if (locationLoading || loadingJobPosition || loadingDashboard) {
    return <div>Loading...</div>;
  }

  // FILTER DASHBBOARD DATA

  // const findConflickData = (() => {
  //   const seen = new Map();
  //   const result = [];

  //   filterToAssign.length > 0 &&
  //     filterToAssign.forEach((item) => {
  //       const key = `${item?.shiftDetails?.startTime}-${item?.shiftDetails?.endTime}`;

  //       if (seen.has(key)) {
  //         // if there is conflick data create property conflickData and push
  //         const existingItem = seen.get(key);
  //         existingItem.conflickData.push(item);
  //       } else {
  //         // if there is no conflick data just push
  //         item.conflickData = [];
  //         seen.set(key, item);
  //         result.push(item);
  //       }
  //     });

  //   return result;
  // })();

  const allDashboard = [
    {
      date: "2024-11-03T17:00:00.000Z",
      employee: {
        department: "Tehnologi",
        firstName: "Kiayada",
        jobPosition: "Front End",
        lastName: "Cobb",
        locationID: "33a345cf-da1f-4e04-ab0d-b9d91fc5948e",
        locationName: "Bandung",
      },
      shiftDetails: {
        shiftName: "shift one",
        startBreakTime: "03:00",
        startTime: "02:00",
        uId: "6731aee5e8bb9e9073dbbf75",
      },
    },
    {
      date: "2024-11-02T17:00:00.000Z",
      employee: {
        department: "Tehnologi",
        firstName: "aldi-employee",
        jobPosition: "Front End",
        lastName: "Peters",
        locationID: "33a345cf-da1f-4e04-ab0d-b9d91fc5948e",
        locationName: "Bandung",
      },
      shiftDetails: {},
    },
  ];

  const result = {
    header: ["2024-11-02T17:00:00.000Z", "2024-11-02T17:00:00.000Z"],
    shift: [
      {
        date: "2024-11-03T17:00:00.000Z",
        employee: {
          department: "Tehnologi",
          firstName: "Kiayada",
          jobPosition: "Front End",
          lastName: "Cobb",
          locationID: "33a345cf-da1f-4e04-ab0d-b9d91fc5948e",
          locationName: "Bandung",
        },
      },
      {
        date: "2024-11-02T17:00:00.000Z",
        employee: {
          department: "Tehnologi",
          firstName: "aldi-employee",
          jobPosition: "Front End",
          lastName: "Peters",
          locationID: "33a345cf-da1f-4e04-ab0d-b9d91fc5948e",
          locationName: "Bandung",
        },
      },
    ],
  };

  const allLocation =
    locationData.data.length > 0 &&
    locationData.data.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  const listAllJobPosition =
    allJobPosition?.data.length > 0 &&
    allJobPosition?.data.map((item) => {
      return {
        label: item.jobPosition,
        value: item.jobPosition,
      };
    });

  const exportToExcel = async () => {
    alert("DOWNLOAD EXCEL");
    // const dataFromRedux = dataExcel.payload;

    // try {
    //   // send data to server
    //   // const responseData = await mutateAsync(dataFromRedux);

    //   // ask to user for choice location directory
    //   const fileHandle = await window.showSaveFilePicker({
    //     suggestedName: "comunity.xlsx",
    //     types: [
    //       {
    //         description: "Excel files",
    //         accept: {
    //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    //             [".xlsx"],
    //         },
    //       },
    //     ],
    //   });

    //   // write data to file you choice
    //   const writable = await fileHandle.createWritable();
    //   // await writable.write(responseData); // write blob to the file
    //   await writable.close();
    // } catch (error) {
    //   return;
    // }
  };

  return (
    <ModalDialog
      title={"Export"}
      onClose={onCLoseModal}
      className={" w-[700px] flex flex-col gap-4"}
    >
      <div className="h-[300px]  flex flex-col gap-6">
        <div className="w-full flex gap-3 ">
          <div
            className={`${
              selectFilterBy !== "Custom Date" ? "w-[32%] " : "w-full"
            }`}
          >
            <InputSelect
              options={[
                {
                  label: "Today",
                  value: "Today",
                },
                {
                  label: "Past Week",
                  value: "Past Week",
                },
                {
                  label: "Past 1 Month",
                  value: "Past 1 Month",
                },
                {
                  label: "Past 3 Month",
                  value: "Past 3 Month",
                },
                {
                  label: "Custom Date",
                  value: "Custom Date",
                },
              ]}
              title={"Filter by"}
              value={selectFilterBy}
              onChange={(e) => setSelectFilterBy(e.target.value)}
            />
          </div>

          {selectFilterBy === "Custom Date" && (
            <>
              <InputDate
                name={"startDate"}
                label={"Start Date"}
                positionCalendar={"right-0"}
                value={startDate}
                // onBlur={handleBlur}
                setFieldValue={(name, val) => {
                  setStartDate(val);
                }}
              />

              <InputDate
                name={"end Date"}
                label={"end Date"}
                positionCalendar={"right-0"}
                value={endDate}
                // onBlur={handleBlur}
                setFieldValue={(name, val) => {
                  setEndDate(val);
                }}
              />
            </>
          )}
        </div>

        <div className="w-full flex gap-3 ">
          <InputSelect
            options={allLocation}
            title={"Locations"}
            value={selectLocation}
            onChange={(e) => setSelectLocation(e.target.value)}
          />
          <InputSelect
            options={listAllJobPosition}
            title={"Job Positions"}
            value={selectJobPosition}
            onChange={(e) => setSelectJobPosition(e.target.value)}
          />
          <InputSelect
            options={listEmployee}
            title={"Employee"}
            value={selectEmployee}
            onChange={(e) => setSelectEmployee(e.target.value)}
          />
        </div>

        <Button
          type={"button"}
          onClick={exportToExcel}
          style={"solid"}
          className={"w-full group group-hover:text-white"}
          label={
            <div className="flex gap-2 items-center">
              {/* <MagicSvg
                className={"group-hover:text-white"}
                color={"orange-500"}
              /> */}
              <h1>Export to csv</h1>
            </div>
          }
        />
      </div>
    </ModalDialog>
  );
}

export default ModalDownloadExcel;
