import React from "react";
import ReplacementConflickProvider from "context/ReplacementConflickProvider";
import { Outlet } from "react-router-dom";
import AllButtons from "./allButton";
import JobPositionViewProvider from "context/jobPositionViewContext";

function Dashboard() {
  return (
    <JobPositionViewProvider>
      <ReplacementConflickProvider>
        <AllButtons />
        <div>
          <Outlet />
        </div>
      </ReplacementConflickProvider>
    </JobPositionViewProvider>
  );
}

export default Dashboard;
