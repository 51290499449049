import React, { useContext } from "react";
import { ModalConfirmation } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";

import { JobPositionContext } from "context/jobPositionViewContext";
import { useDeleteAssignShift } from "services/assignShiftService";
import { alertSuccess, alertError } from "services/alert";

function ModalDeleteAssignShift({ setConfirmDeleteAssignshift }) {
  const queryClient = useQueryClient();
  const { setShowModalEditAssign, uIdAssignShiftDetail, editAssignShift } =
    useContext(JobPositionContext);

  // DELETE ASSIGN SHIFT
  const { mutate: deleteAssignShift, isLoading: loadingDeleteAssignShift } =
    useDeleteAssignShift({
      uId: uIdAssignShiftDetail,
      onSuccess: () => {
        alertSuccess("Data Assign Shift has been Deleted");
        queryClient.invalidateQueries("getDashboard");
        setConfirmDeleteAssignshift(false);
        setShowModalEditAssign(false);
      },
      onError: (err) => {
        alertError("Something Wrong!");
        console.log("error : ", err);
      },
    });

  if (loadingDeleteAssignShift) {
    return <div>Loading...</div>;
  }

  return (
    <ModalConfirmation
      title={"Delete One Assign Shift Detail"}
      headMessage={"This is will be Delete one Assign Shift !"}
      onClose={() => {
        setConfirmDeleteAssignshift(false);
      }}
      onConfirm={() => {
        deleteAssignShift(editAssignShift.toFormValue);
      }}
    />
  );
}

export default ModalDeleteAssignShift;
