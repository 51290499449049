import React from "react";
import { useCreateShift } from "services/shiftServices";
import { useSelector } from "react-redux";

import { createShiftSchema } from "./createShiftSchema";
import FormShift from "shared/formShift/formShift";
import { FiFilePlus } from "assets/icons";

function FormCreateShift({ setShowCreateShift }) {
  const { mutate, isLoading } = useCreateShift();

  const { locationState } = useSelector((state) => state.employeeView);
  const currentUser = JSON.parse(localStorage.getItem("currentUser")) || "";

  const initialValue = {
    shiftName: "",
    color: "red",
    startTime: "",
    endTime: "",
    organizationId: currentUser.organizationID,

    startBreakTime: "",
    endBreakTime: "",

    // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
    repeatDate: [],
    repeatBy: {
      repeatType: "Weekly",
      repeatValue: [],
    },
    locationId: locationState,
    // locationId: "",

    employees: [
      {
        employeesNeeded: 0,
        jobPosition: "",
      },
    ],
  };

  const handleSubmitForm = (val) => {
    const allVal = {
      ...val,
      repeatDate: {
        from: val.repeatDate[0],
        to: val.repeatDate[val.repeatDate.length - 1],
      },
    };

    mutate(allVal);

    // SHOW CREATE SHIFT FALSE
    setShowCreateShift(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormShift
      initialValue={initialValue}
      validationSchema={createShiftSchema}
      setShowCreateShift={setShowCreateShift}
      handleSubmit={handleSubmitForm}
      defaultValue={"Cafe Halim"}
      nameButton={"Create new Shift"}
      iconButton={<FiFilePlus />}
    />
  );
}

export default FormCreateShift;
