import React from "react";

function CentangIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" fill="#4BD394" />
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="#FDFDFD" />
      <path
        d="M17.647 8.26465L9.88232 16.0294L6.35291 12.4999"
        stroke="#FDFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CentangIcon;
