import React from "react";
import PropTypes from "prop-types";

function CloseRound({ className, color, ...props }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M12.5 4L4.5 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4L12.5 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CloseRound.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

CloseRound.defaultProps = {
  className: "",
};

export default CloseRound;
