import AppRouter from "routes";
import AlertService from "components/alertService";
import RefProvider from "context/refProvider";
import RoleViewProvider from "context/jobPositionViewContext";

const App = () => {
  return (
    <RefProvider>
      <RoleViewProvider>
        <AppRouter />

        <AlertService />
      </RoleViewProvider>
    </RefProvider>
  );
};

export default App;
