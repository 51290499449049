import { createSlice } from '@reduxjs/toolkit'

export const jobPositionView = createSlice({
    name: 'jobPositionView',
    initialState: {
        dataDate: [],
        shiftDataTemplate: [],
        locationState: "",
        locationLabelName: "",
        dateRange: {},
        dataEmployees: [],
        statusConflick: false,
        statusReplace: false,
    },
    reducers: {
        setDataTemplate: (state, action) => {
            state.shiftDataTemplate.push(action.payload)
        },

        setDateRange: (state, action) => {
            state.dateRange = action.payload
        },
        setDataEmployee: (state, action) => {
            state.dataEmployees = action.payload
        },

        setConflickStatus: (state, action) => {
            state.statusConflick = action.payload;
        },
        setReplaceStatus: (state, action) => {
            state.statusReplace = action.payload;
        },

    },

})

export const { setDataDate, setDataTemplate, setDateRange, setDataEmployee, setLocationLabelName, setConflickStatus, setReplaceStatus } = jobPositionView.actions;

export default jobPositionView.reducer;

