import React from "react";
import PropTypes from "prop-types";

function PaperUpload({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82439 1.84084H5.38972C4.00306 1.83551 2.86706 2.94017 2.83372 4.32684V11.4848C2.80372 12.8862 3.91572 14.0462 5.31639 14.0762C5.34106 14.0762 5.36572 14.0768 5.38972 14.0762H10.7151C12.1084 14.0268 13.2097 12.8788 13.2017 11.4848V5.35817L9.82439 1.84084Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6499 1.83301V3.77234C9.6499 4.71901 10.4159 5.48634 11.3626 5.48901H13.1986"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76058 6.60547V10.6328"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32442 8.17547L7.76109 6.60547L6.19775 8.17547"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PaperUpload.propTypes = {
  className: PropTypes.string,
};

PaperUpload.defaultProps = {
  className: "text-[#201140]",
};

export default PaperUpload;
