import React from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import EditAssignShift from "../assignShift/editAssignShift";
import { FiTrashNoBold } from "assets/icons";

function ModalEditAssignShift({
  dataEmployees,
  showRequestReplacement,
  setConfirmDeleteAssignshift,
  setTitleReplacement,
  titleReplacement,
  setShowModalEditAssign,
  currentRole,
  shiftNameAssign,
  setValueShiftName,
  editAssignShift,
  assignSelectJob,
  uIdAssignShiftDetail,
}) {
  return (
    <>
      <ModalDialog
        icon={[
          {
            icon: (
              <div
                className={` ${
                  showRequestReplacement && "hidden"
                } flex gap-3 items-center mr-2`}
              >
                {currentRole === "Admin" && (
                  <FiTrashNoBold
                    onClick={() => {
                      setConfirmDeleteAssignshift(true);
                    }}
                  />
                )}
              </div>
            ),
          },
        ]}
        onClose={() => {
          setShowModalEditAssign(false);
          setTitleReplacement(false);

          // setShiftNameAssign([]);
        }}
        title={titleReplacement ? "Shift Details" : "Edit Assign Shift"}
        className={"h-[700px] w-[750px] "}
      >
        <EditAssignShift
          shiftNameAssign={shiftNameAssign}
          setValueShiftName={setValueShiftName}
          shiftData={editAssignShift}
          dataEmployee={dataEmployees}
          selectJobs={assignSelectJob}
          uIdData={uIdAssignShiftDetail}
          setShowModalEditAssign={setShowModalEditAssign}
          setTitleReplacement={setTitleReplacement}
        />
      </ModalDialog>
    </>
  );
}

export default ModalEditAssignShift;
