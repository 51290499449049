import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LayoutShiftScheduling from "layouts/adminLayout";
import {
  Dashboard,
  EmployeeView,
  JobPositionView,
} from "pages/dashboard/indexShare";

import { useDispatch, useSelector } from "react-redux";
import { setLoginReducer } from "store/userLoginSlice";
import { jwtDecode } from "jwt-decode";

// DONT FORGET TO DELETE THIS =================================
// import HomeCoba from 'coba/HomeCoba';

const AppRouter = ({ props }) => {
  // CEK FROM API USER ROLE

  const dispatch = useDispatch();
  const { currentUser, currentRole, token } = useSelector(
    (state) => state?.loginSlice
  );

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem("accessToken");
    const localStorageCurrentRole = localStorage.getItem("currentRole");
    if (localStorageAccessToken) {
      const user = jwtDecode(localStorageAccessToken);

      if (!localStorageCurrentRole) {
        localStorage.setItem("currentRole", user?.role[0]);
      }
      dispatch(
        setLoginReducer({
          currentUser: user,
          token: localStorageAccessToken,
          currentRole: localStorageCurrentRole ?? currentRole ?? user?.role[0],
        })
      );
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    if (typeof props?.setGlobalState === "function") {
      props.setGlobalState({
        currentUser: currentUser,
        accessToken: token,
        currentRole: currentRole,
      });
    }
  }, [props, currentUser, token, currentRole]);

  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? "/scheduling" : "/"}>
      <Routes>
        {/* <Route path='/coba' element={<HomeCoba />} /> */}
        <Route element={<LayoutShiftScheduling />}>
          <Route element={<Dashboard />}>
            <Route index path="/" element={<EmployeeView />} />
            <Route path="/jobPositionView" element={<JobPositionView />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
