import React from "react";
import { ModalDialog, Button } from "@bluesilodev/timhutcomponents";
import ApproveData from "./approveData";
import { CloseRound, FiCheck } from "assets/icons";
import { currentUser } from "utils/localstorage/user";

function RequestApproveModal({
  onReject,
  onAccept,
  setApproveModal,
  dateRequest,
  assignShiftDate,
  shiftName,
  locationName,
  startTime,
  endTime,
  employeeName,
  requestorPhoto,
  nameReplacement,
  replacementPhoto,
  statusAproveReplacement,
  statusAproveHr,
  hrAdminName,
  hrAdminPhoto,
  jobPosition,
  adminId,
  replacementUserData,
}) {
  const roleAdmin = adminId === currentUser?.uId;
  const roleEmployee = replacementUserData?.uId === currentUser?.uId;
  let isShowButton;

  if (currentUser.role.includes("Admin")) {
    isShowButton =
      statusAproveReplacement === "approved" &&
      statusAproveHr !== "approved" &&
      roleAdmin;
  } else if (currentUser.role.includes("Employee")) {
    isShowButton = statusAproveReplacement === "pending" && roleEmployee;
  }

  return (
    <ModalDialog
      title={"Replacement Request"}
      onClose={() => {
        setApproveModal(false);
      }}
      className={"w-[700px] h-[500px]"}
    >
      <p>
        <span className="font-semibold">{employeeName}</span> asked{" "}
        <span className="font-semibold">{nameReplacement}</span> for a
        replacement{" "}
      </p>

      <ApproveData
        dateRequest={dateRequest}
        assignShiftDate={assignShiftDate}
        shiftName={shiftName}
        locationName={locationName}
        startTime={startTime}
        endTime={endTime}
        employeeName={employeeName}
        requestorPhoto={requestorPhoto}
        nameReplacement={nameReplacement}
        replacementPhoto={replacementPhoto}
        statusAproveReplacement={statusAproveReplacement}
        statusAproveHr={statusAproveHr}
        hrAdminName={hrAdminName}
        hrAdminPhoto={hrAdminPhoto}
        jobPosition={jobPosition}
      />

      {isShowButton && <p className="mt-3">Shall we approve this Request ? </p>}

      <div className="flex gap-3 w-full justify-center items-center mt-5">
        {isShowButton && (
          <>
            <Button
              onClick={onReject}
              type={"button"}
              className={"w-full"}
              label={
                <div className="flex gap-2 items-center">
                  <CloseRound />
                  <h1>Reject Shift</h1>
                </div>
              }
            />

            <Button
              onClick={onAccept}
              type={"button"}
              className={"w-full"}
              label={
                <div className="flex gap-2 items-center">
                  <FiCheck />
                  <h1>Accept Shift</h1>
                </div>
              }
              style={"solid"}
            />
          </>
        )}
      </div>
    </ModalDialog>
  );
}

export default RequestApproveModal;
