import React from "react";

function LogoTimhut() {
  return (
    <svg
      width="164"
      height="40"
      viewBox="0 0 164 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="font-latoBold"
    >
      <g clipPath="url(#clip0_2_14734)">
        <path
          d="M14.8671 28.2156C14.8671 26.5524 14.8671 24.8934 14.8671 23.2302C14.8671 22.6493 14.8964 22.0727 14.9717 21.496C14.9299 21.8052 14.8881 22.1186 14.8462 22.4279C14.9885 21.3832 15.2645 20.3635 15.6745 19.3898C15.5574 19.6698 15.4402 19.9498 15.3189 20.2298C15.7163 19.2979 16.2267 18.4203 16.8458 17.618C16.6618 17.8562 16.4777 18.0902 16.2978 18.3284C16.9253 17.5177 17.6532 16.7906 18.4647 16.1638C18.2263 16.3476 17.992 16.5315 17.7536 16.7112C18.5568 16.0969 19.4352 15.5829 20.3681 15.1859C20.0878 15.3029 19.8076 15.4199 19.5273 15.5411C20.502 15.1316 21.5227 14.8558 22.5685 14.7137C22.2589 14.7555 21.9452 14.7973 21.6356 14.8391C22.535 14.7221 23.4344 14.7346 24.338 14.7346C25.4047 14.7346 26.4673 14.7346 27.534 14.7346C27.8101 14.7346 28.0862 14.7346 28.3623 14.7346C29.2617 14.7346 30.2113 14.3418 30.8471 13.7066C31.1902 13.389 31.4328 13.0129 31.575 12.5783C31.7967 12.1604 31.8971 11.7091 31.8762 11.2244C31.8344 10.3175 31.5374 9.37313 30.8471 8.74212C30.1611 8.11111 29.3161 7.71412 28.3623 7.71412C26.9191 7.71412 25.4758 7.71412 24.0368 7.71412C23.3215 7.71412 22.6061 7.70576 21.895 7.78516C20.9496 7.88963 20.0042 8.015 19.088 8.26991C18.197 8.51646 17.3311 8.88002 16.4902 9.26865C15.913 9.53192 15.3566 9.84534 14.8295 10.2005C14.0765 10.7062 13.3444 11.2703 12.6793 11.8888C12.215 12.315 11.7883 12.7789 11.3992 13.2762C10.8303 13.9991 10.2781 14.7137 9.82633 15.516C9.37454 16.3184 9.0106 17.1792 8.69685 18.0401C8.47096 18.6585 8.29526 19.2937 8.17395 19.9415C7.95642 21.0948 7.84766 22.2565 7.84766 23.4266C7.84766 24.9811 7.84766 26.5357 7.84766 28.0944C7.84766 28.1362 7.84766 28.178 7.84766 28.2156C7.84766 29.114 8.24088 30.0626 8.87673 30.6978C9.19466 31.0405 9.57115 31.2829 10.0062 31.4249C10.4245 31.6464 10.8763 31.7467 11.3616 31.7258C12.2693 31.684 13.2148 31.3873 13.8464 30.6978C14.4697 30.0125 14.8671 29.1642 14.8671 28.2156Z"
          fill="#00AFF4"
        />
        <path
          d="M23.991 29.6657C27.2047 29.6657 29.8099 27.0633 29.8099 23.8529C29.8099 20.6426 27.2047 18.0402 23.991 18.0402C20.7773 18.0402 18.1721 20.6426 18.1721 23.8529C18.1721 27.0633 20.7773 29.6657 23.991 29.6657Z"
          fill="#DD7224"
        />
        <path
          d="M33.1063 19.5027C33.1063 21.1659 33.1063 22.8249 33.1063 24.4881C33.1063 25.0689 33.077 25.6456 33.0017 26.2223C33.0435 25.913 33.0853 25.5996 33.1272 25.2904C32.9849 26.3351 32.7089 27.3548 32.2989 28.3284C32.416 28.0484 32.5332 27.7685 32.6545 27.4885C32.2571 28.4204 31.7467 29.2979 31.1276 30.1003C31.3116 29.8621 31.4957 29.6281 31.6756 29.3899C31.0481 30.2006 30.3202 30.9277 29.5087 31.5545C29.7471 31.3706 29.9814 31.1868 30.2198 31.0071C29.4166 31.6214 28.5382 32.1354 27.6053 32.5324C27.8856 32.4153 28.1658 32.2983 28.4461 32.1772C27.4714 32.5867 26.4507 32.8625 25.4049 33.0046C25.7145 32.9628 26.0282 32.921 26.3378 32.8792C25.4384 32.9962 24.539 32.9837 23.6354 32.9837C22.5687 32.9837 21.5061 32.9837 20.4394 32.9837C20.1633 32.9837 19.8872 32.9837 19.6111 32.9837C18.7117 32.9837 17.7621 33.3765 17.1263 34.0117C16.7832 34.3293 16.5406 34.7054 16.3984 35.14C16.1767 35.5578 16.0763 36.0092 16.0972 36.4939C16.139 37.4007 16.436 38.3451 17.1263 38.9762C17.8123 39.6072 18.6573 40.0041 19.6111 40.0041C21.0543 40.0041 22.4976 40.0041 23.9366 40.0041C24.6519 40.0041 25.3673 40.0125 26.0784 39.9331C27.0238 39.8286 27.9692 39.7033 28.8854 39.4484C29.7764 39.2018 30.6423 38.8382 31.4832 38.4496C32.0604 38.1863 32.6168 37.8729 33.1439 37.5177C33.8969 37.0121 34.629 36.4479 35.2941 35.8295C35.7584 35.4032 36.1851 34.9394 36.5742 34.4421C37.1431 33.7192 37.6953 33.0046 38.1471 32.2022C38.5989 31.3999 38.9628 30.539 39.2765 29.6782C39.5024 29.0597 39.6781 28.4245 39.7995 27.7768C40.017 26.6235 40.1257 25.4617 40.1257 24.2917C40.1257 22.7371 40.1257 21.1826 40.1257 19.6239C40.1257 19.5821 40.1257 19.5403 40.1257 19.5027C40.1257 18.6042 39.7325 17.6556 39.0967 17.0204C38.7787 16.6778 38.4022 16.4354 37.9672 16.2933C37.5489 16.0718 37.0971 15.9716 36.6118 15.9924C35.7041 16.0342 34.7586 16.3309 34.127 17.0204C33.5037 17.7058 33.1063 18.5499 33.1063 19.5027Z"
          fill="#DD7224"
        />
        <path
          d="M5.81889 11.6256C9.03258 11.6256 11.6378 9.0231 11.6378 5.81279C11.6378 2.60247 9.03258 0 5.81889 0C2.60521 0 0 2.60247 0 5.81279C0 9.0231 2.60521 11.6256 5.81889 11.6256Z"
          fill="#00AFF4"
        />
      </g>
      <path
        d="M54.8419 39.2261C52.3072 39.2261 50.3689 38.6742 49.027 37.5705C47.6851 36.4368 47.0142 34.6618 47.0142 32.2454V9.82656C47.0142 9.37908 47.2527 9.02109 47.7298 8.7526C48.2368 8.45428 48.7735 8.30512 49.3401 8.30512C49.9067 8.30512 50.4435 8.45428 50.9504 8.7526C51.4573 9.02109 51.7108 9.37908 51.7108 9.82656V18.0155H57.1679C57.5854 18.0155 57.8985 18.1945 58.1072 18.5525C58.3458 18.9104 58.465 19.3132 58.465 19.7607C58.465 20.2081 58.3458 20.6109 58.1072 20.9689C57.8985 21.3269 57.5854 21.5058 57.1679 21.5058H51.7108V32.2454C51.7108 33.3194 51.9345 34.0801 52.3818 34.5276C52.8589 34.9751 53.6789 35.1988 54.8419 35.1988H56.3627C57.0486 35.1988 57.5555 35.4076 57.8836 35.8253C58.2414 36.2131 58.4203 36.6755 58.4203 37.2125C58.4203 37.7196 58.2414 38.182 57.8836 38.5997C57.5555 39.0173 57.0486 39.2261 56.3627 39.2261H54.8419Z"
        fill="#1A1A2E"
      />
      <path
        d="M64.0193 12.6904C63.2738 12.6904 62.6326 12.4369 62.0959 11.9297C61.5591 11.4226 61.2907 10.8558 61.2907 10.2293C61.2907 9.54315 61.5591 8.96143 62.0959 8.48411C62.6326 8.0068 63.2738 7.76814 64.0193 7.76814C64.7648 7.76814 65.391 8.0068 65.8979 8.48411C66.4347 8.96143 66.7031 9.54315 66.7031 10.2293C66.7031 10.8558 66.4347 11.4226 65.8979 11.9297C65.391 12.4369 64.7648 12.6904 64.0193 12.6904ZM64.0193 39.2261C63.3334 39.2261 62.7668 39.0621 62.3195 38.7339C61.902 38.4058 61.6933 38.0478 61.6933 37.66V19.5369C61.6933 19.0894 61.902 18.7315 62.3195 18.463C62.7668 18.1646 63.3334 18.0155 64.0193 18.0155C64.6753 18.0155 65.227 18.1646 65.6743 18.463C66.1514 18.7315 66.39 19.0894 66.39 19.5369V37.66C66.39 38.0478 66.1514 38.4058 65.6743 38.7339C65.227 39.0621 64.6753 39.2261 64.0193 39.2261Z"
        fill="#1A1A2E"
      />
      <path
        d="M73.5855 39.2261C72.8997 39.2261 72.3331 39.0621 71.8858 38.7339C71.4683 38.4058 71.2596 38.0478 71.2596 37.66V19.5369C71.2596 19.0894 71.4683 18.7315 71.8858 18.463C72.3331 18.1646 72.8997 18.0155 73.5855 18.0155C74.1521 18.0155 74.6442 18.1646 75.0616 18.463C75.4791 18.7315 75.6879 19.0894 75.6879 19.5369V21.1479C76.1948 20.2827 76.9403 19.5071 77.9244 18.821C78.9084 18.105 80.146 17.747 81.637 17.747C82.9789 17.747 84.1567 18.1646 85.1706 18.9999C86.2143 19.8352 86.9896 20.9092 87.4966 22.2218C88.2719 20.7004 89.2709 19.5817 90.4935 18.8657C91.7161 18.1199 92.9686 17.747 94.2508 17.747C95.5927 17.747 96.8452 18.0901 98.0081 18.7762C99.2009 19.4623 100.17 20.4617 100.916 21.7743C101.661 23.0869 102.034 24.683 102.034 26.5624V37.66C102.034 38.0478 101.795 38.4058 101.318 38.7339C100.871 39.0621 100.319 39.2261 99.6632 39.2261C99.0369 39.2261 98.4853 39.0621 98.0081 38.7339C97.5608 38.4058 97.3372 38.0478 97.3372 37.66V26.5624C97.3372 25.5481 97.1285 24.6979 96.711 24.0117C96.3233 23.2958 95.8015 22.7588 95.1454 22.4008C94.5192 22.0428 93.8632 21.8638 93.1773 21.8638C92.4616 21.8638 91.7907 22.0577 91.1644 22.4456C90.5382 22.8035 90.0164 23.3256 89.5989 24.0117C89.1814 24.6681 88.9727 25.4884 88.9727 26.4729V37.7047C88.9727 38.2417 88.7192 38.6295 88.2123 38.8682C87.7351 39.1068 87.1984 39.2261 86.602 39.2261C86.0652 39.2261 85.5434 39.1068 85.0364 38.8682C84.5295 38.6295 84.276 38.2417 84.276 37.7047V26.5176C84.276 25.563 84.0822 24.7426 83.6945 24.0565C83.3069 23.3704 82.7999 22.8483 82.1737 22.4903C81.5475 22.1025 80.8616 21.9086 80.1161 21.9086C79.4303 21.9086 78.7593 22.0876 78.1033 22.4456C77.4771 22.8035 76.9552 23.3256 76.5377 24.0117C76.1501 24.6979 75.9562 25.5481 75.9562 26.5624V37.66C75.9562 38.0478 75.7177 38.4058 75.2406 38.7339C74.7933 39.0621 74.2416 39.2261 73.5855 39.2261Z"
        fill="#1A1A2E"
      />
      <path
        d="M109.143 39.2261C108.458 39.2261 107.891 39.0621 107.444 38.7339C107.026 38.4058 106.818 38.0478 106.818 37.66V6.91792C106.818 6.47044 107.026 6.11246 107.444 5.84397C107.891 5.54564 108.458 5.39648 109.143 5.39648C109.8 5.39648 110.351 5.54564 110.798 5.84397C111.276 6.11246 111.514 6.47044 111.514 6.91792V21.1479C111.961 20.2827 112.662 19.5071 113.616 18.821C114.601 18.105 115.808 17.747 117.24 17.747C118.641 17.747 119.923 18.1497 121.086 18.9552C122.279 19.7308 123.233 20.7899 123.949 22.1323C124.665 23.4449 125.023 24.9216 125.023 26.5624V37.66C125.023 38.1671 124.769 38.5549 124.262 38.8234C123.785 39.0919 123.248 39.2261 122.652 39.2261C122.115 39.2261 121.593 39.0919 121.086 38.8234C120.579 38.5549 120.326 38.1671 120.326 37.66V26.5624C120.326 25.7868 120.117 25.041 119.7 24.325C119.282 23.609 118.731 23.0273 118.045 22.5798C117.389 22.1323 116.643 21.9086 115.808 21.9086C115.093 21.9086 114.407 22.1025 113.751 22.4903C113.095 22.8483 112.558 23.3704 112.14 24.0565C111.723 24.7426 111.514 25.5779 111.514 26.5624V37.66C111.514 38.0478 111.276 38.4058 110.798 38.7339C110.351 39.0621 109.8 39.2261 109.143 39.2261Z"
        fill="#1A1A2E"
      />
      <path
        d="M138.741 39.5841C137.071 39.5841 135.521 39.1814 134.089 38.3759C132.688 37.5406 131.569 36.4517 130.734 35.1093C129.899 33.737 129.482 32.2902 129.482 30.7687V19.5817C129.482 19.1939 129.706 18.8359 130.153 18.5077C130.63 18.1796 131.197 18.0155 131.853 18.0155C132.479 18.0155 133.016 18.1796 133.463 18.5077C133.94 18.8359 134.179 19.1939 134.179 19.5817V30.7687C134.179 31.5444 134.372 32.2902 134.76 33.0061C135.178 33.7221 135.729 34.3038 136.415 34.7513C137.131 35.1988 137.906 35.4225 138.741 35.4225C139.576 35.4225 140.336 35.1988 141.022 34.7513C141.708 34.3038 142.245 33.7221 142.633 33.0061C143.05 32.2902 143.259 31.5444 143.259 30.7687V19.5369C143.259 19.1193 143.497 18.7613 143.975 18.463C144.481 18.1646 145.033 18.0155 145.63 18.0155C146.315 18.0155 146.867 18.1646 147.285 18.463C147.732 18.7613 147.956 19.1193 147.956 19.5369V30.7687C147.956 32.3498 147.538 33.8116 146.703 35.1541C145.868 36.4965 144.75 37.5705 143.348 38.3759C141.947 39.1814 140.411 39.5841 138.741 39.5841Z"
        fill="#1A1A2E"
      />
      <path
        d="M160.377 39.2261C157.842 39.2261 155.904 38.6742 154.562 37.5705C153.22 36.4368 152.549 34.6618 152.549 32.2454V9.82656C152.549 9.37908 152.788 9.02109 153.265 8.7526C153.772 8.45428 154.308 8.30512 154.875 8.30512C155.442 8.30512 155.978 8.45428 156.485 8.7526C156.992 9.02109 157.246 9.37908 157.246 9.82656V18.0155H162.703C163.12 18.0155 163.433 18.1945 163.642 18.5525C163.881 18.9104 164 19.3132 164 19.7607C164 20.2081 163.881 20.6109 163.642 20.9689C163.433 21.3269 163.12 21.5058 162.703 21.5058H157.246V32.2454C157.246 33.3194 157.469 34.0801 157.917 34.5276C158.394 34.9751 159.214 35.1988 160.377 35.1988H161.898C162.584 35.1988 163.09 35.4076 163.418 35.8253C163.776 36.2131 163.955 36.6755 163.955 37.2125C163.955 37.7196 163.776 38.182 163.418 38.5997C163.09 39.0173 162.584 39.2261 161.898 39.2261H160.377Z"
        fill="#1A1A2E"
      />
      <defs>
        <clipPath id="clip0_2_14734">
          <rect width="40.1341" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoTimhut;
