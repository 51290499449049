import React, { useContext, createContext, useEffect, useState } from "react";

const RefContext = createContext();

export const useDivRef = () => useContext(RefContext);

function RefProvider({ children }) {
  const [remainingHeight, setRemainingHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const totalHeight = 55 + 60 + 260 + 60;

      // Set height thay left
      setRemainingHeight(window.innerHeight - totalHeight);
    };

    // count height when firstime render
    handleResize();

    // Add event listener for changes screen size
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when komponen unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contextVal = {
    remainingHeight,
  };

  return (
    <RefContext.Provider value={contextVal}>{children}</RefContext.Provider>
  );
}

export default RefProvider;
