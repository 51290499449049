import { createSlice } from '@reduxjs/toolkit'

export const employeeViewSlice = createSlice({
    name: 'employeeView',
    initialState: {
        dataDate: [],
        shiftDataTemplate: [],
        locationState: "",
        locationLabelName: "",
        dateRange: {},
        dataEmployees: [],
        statusConflick: false,
        statusReplace: false,
        selectView: ""
    },
    reducers: {
        setDataDate: (state, action) => {
            state.dataDate = action.payload;
        },
        setDataTemplate: (state, action) => {
            state.shiftDataTemplate.push(action.payload)
        },
        setLocationState: (state, action) => {
            state.locationState = action.payload
        },

        setDateRange: (state, action) => {
            state.dateRange = action.payload
        },
        setDataEmployee: (state, action) => {
            state.dataEmployees = action.payload
        },

        setConflickStatus: (state, action) => {
            state.statusConflick = action.payload;
        },
        setReplaceStatus: (state, action) => {
            state.statusReplace = action.payload;
        },
        setSelectView: (state, action) => {
            state.selectView = action.payload
        }

    },

})

export const { setDataDate, setDataTemplate, setLocationState, setDateRange, setDataEmployee, setLocationLabelName, setConflickStatus, setReplaceStatus, setSelectView } = employeeViewSlice.actions;

export default employeeViewSlice.reducer;

