import { createSlice } from '@reduxjs/toolkit'

export const requestReplacement = createSlice({
    name: 'requestReplacement',
    initialState: {
        showRequestReplacement: false,
        replacementsData: []
    },
    reducers: {
        setShowRequesReplacement: (state, action) => {
            state.showRequestReplacement = action.payload;
        },
        setDataReplacement: (state, action) => {
            state.replacementsData = action.payload
        }
    },

})

export const { setShowRequesReplacement, setDataReplacement } = requestReplacement.actions;

export default requestReplacement.reducer;

