import { createContext, useContext } from "react";
import {
  useGetHistoryReplacement,
  useGetAllConflickData,
} from "services/conflickDataApi";

export const ChangeBorderContext = createContext();

export const useChangeBorder = () => useContext(ChangeBorderContext);

function ChangeBorderProvider({ children, valuesDate }) {
  let statusBorder = false;

  const fromDate = valuesDate.length > 0 && valuesDate[0];
  const toDate = valuesDate.length > 0 && valuesDate[valuesDate.length - 1];

  const { data: historyReplace, isLoading: loadingReplace } =
    useGetHistoryReplacement();

  const { data: conflickData, isLoading: loadingConflick } =
    useGetAllConflickData({
      fromDate,
      toDate,
    });

  if (loadingConflick || loadingReplace) {
    return <div>Loading...</div>;
  }

  if (historyReplace?.data?.length > 0 || conflickData?.conflicts?.length > 0) {
    statusBorder = true;
  } else {
    statusBorder = false;
  }

  const contextVal = {
    statusBorder,
  };

  return (
    <ChangeBorderContext.Provider value={contextVal}>
      {children}
    </ChangeBorderContext.Provider>
  );
}

export default ChangeBorderProvider;
