import React from "react";
import {
  CentangIcon,
  ArrowRight,
  PendingIcon,
  CancelXIcon,
} from "assets/icons";
import { formatDateIndEng } from "utils/common/generateDateRange";
import { currentUser } from "utils/localstorage/user";

function ApproveData({
  dateRequest,
  shiftName,
  startTime,
  endTime,
  employeeName,
  nameReplacement,
  hrAdminName,
  assignShiftDate,
  locationName,
  jobPosition,
  requestorPhoto,
  replacementPhoto,
  hrAdminPhoto,
  handleApproveRequest,
  statusAproveReplacement,
  statusAproveHr,
  replacementUserData,
  adminId,
  statusClick,
}) {
  // status ["approved", "pending", "rejected"]

  // CECK IF USER REPLACEMENT APPROVE
  const ceckReplacement =
    currentUser?.uId === replacementUserData?.uId &&
    statusAproveReplacement === "pending";

  const isAdminApprove =
    statusAproveReplacement === "approved" && currentUser.uId === adminId;

  // FUNCTION CECK STATUS APROVE
  const ceckApproove = () => {
    if (
      statusAproveReplacement === "rejected" ||
      statusAproveHr === "rejected"
    ) {
      return "Rejected";
    } else if (
      statusAproveReplacement === "pending" ||
      statusAproveHr === "pending"
    ) {
      return "Pending";
    } else {
      return "Approved";
    }
  };

  // (ceckReplacement || isAdminApprove) &&
  return (
    <div
      className={`border-[1px] border-gray-300 p-3 rounded-md mt-5 ${
        statusClick && "cursor-pointer hover:border-secondary"
      }`}
      onClick={
        // ceckReplacement || isAdminApprove ? handleApproveRequest : () => {}
        statusClick ? handleApproveRequest : () => {}
      }
    >
      <div>
        <h1>Requested on {formatDateIndEng(dateRequest)}</h1>
        <div className="w-full flex justify-between items-center">
          <p className="font-semibold">
            {shiftName} on {formatDateIndEng(assignShiftDate)}
          </p>

          <div
            className={`${statusAproveHr === "approved" && "bg-[#A5F2CE]"} ${
              statusAproveHr === "pending" && "bg-gray-400 "
            } ${
              (statusAproveHr === "rejected" ||
                statusAproveReplacement === "rejected") &&
              "bg-red-500"
            }  h-[57px] rounded-xl flex justify-center items-center w-[183px]`}
          >
            <h1 className="text-white font-semibold">{ceckApproove()}</h1>
          </div>
        </div>
        <h1 className="font-semibold">
          {locationName} - {jobPosition} ({startTime} - {endTime})
        </h1>

        <div className="flex gap-5  mt-5 items-center">
          <div className="flex gap-5">
            <div className="relative">
              <img
                src={requestorPhoto}
                alt="employee"
                className="h-[48px] w-[48px] rounded-full"
              />

              <div className="absolute top-[-2px] right-[-14px]">
                <CentangIcon />
              </div>
            </div>

            <div className="flex flex-col ">
              <p>Requestor</p>
              <h1 className="font-semibold">{employeeName}</h1>
            </div>
          </div>
          <ArrowRight />

          <div className="flex gap-5">
            <div className="relative">
              <img
                src={replacementPhoto}
                alt="employee"
                className="h-[48px] w-[48px] rounded-full"
              />

              <div className="absolute top-[-2px] right-[-14px]">
                {statusAproveReplacement === "pending" && <PendingIcon />}
                {statusAproveReplacement === "rejected" && <CancelXIcon />}
                {statusAproveReplacement === "approved" && <CentangIcon />}
              </div>
            </div>

            <div className="flex flex-col ">
              <p>Replacements</p>
              <h1 className="font-semibold">{nameReplacement}</h1>
            </div>
          </div>

          <ArrowRight />

          <div className="flex gap-5">
            <div className="relative">
              <img
                src={hrAdminPhoto}
                alt="employee"
                className="h-[48px] w-[48px] rounded-full"
              />

              <div className="absolute top-[-2px] right-[-14px]">
                {statusAproveHr === "pending" && <PendingIcon />}
                {statusAproveHr === "rejected" && <CancelXIcon />}
                {statusAproveHr === "approved" && <CentangIcon />}
              </div>
            </div>

            <div className="flex flex-col ">
              <p>HR Admin</p>
              <h1 className="font-semibold">{hrAdminName}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproveData;
