import { useMutation, useQuery } from '@tanstack/react-query';
import customAxios from 'utils/axios';

// const API = ;
const DASHBOARD_API = `${process.env.REACT_APP_API_SHIFT}/api/assignshift/dashboard`
const URL_ASSIGN = `${process.env.REACT_APP_API_SHIFT}/api/assignshift`


export const usePostAssignShift = ({ onSuccess, onError }) => {
    const assignShift = async (body) => {
        const resp = await customAxios.post(`${URL_ASSIGN}`, body)
        return resp.data;
    }

    return useMutation({
        mutationFn: assignShift,
        onSuccess,
        onError
    })
}

export const useUpdateAssignShift = (props) => {
    const { uId, onSuccess, onError } = props

    const updateAssign = async (body) => {

        const resp = await customAxios.put(`${URL_ASSIGN}/${uId}`, body)
        return resp.data
    }

    return useMutation({
        mutationFn: updateAssign,
        onSuccess,
        onError
    })
}

export const useGetByIdAssign = ({ uId }) => {
    const getAssignById = async () => {
        const resp = await customAxios.get(`${URL_ASSIGN}/${uId}`)
        return resp.data;
    }

    return useQuery({
        queryFn: getAssignById,
        queryHash: ["getAssignById"],
        onError: (err) => {
            console.log("Something Wrong ", err)
        }
    })
}


export const useDeleteAssignShift = (props) => {
    const { uId, onSuccess, onError } = props

    const deleteAssignShift = async () => {
        const resp = await customAxios.delete(`${URL_ASSIGN}/${uId}`)
        return resp.data;
    }

    return useMutation({
        mutationFn: deleteAssignShift,
        onSuccess,
        onError
    })
}


export const useGetAssignShift = () => {

    const getAssign = async () => {
        const resp = await customAxios.get(`${URL_ASSIGN}`)
        return resp.data;
    }

    return useQuery({
        queryKey: ["getAssignShift"],
        queryFn: getAssign,
        onError: (err) => {
            console.log("Something wrong", err)
        }
    });
}

// UPDATE OR PUBLISH DATA 
export const usePublishAssignShift = ({ onSuccess, onError }) => {

    const publish = async (body) => {
        const resp = await customAxios.put(`${URL_ASSIGN}/publish`, body)
        return resp.data;
    }

    return useMutation({
        mutationFn: publish,
        onSuccess,
        onError
    })
}

// GET ALL DATA DASHBOARD
export const useGetAllDashboard = (queryData = {}) => {

    const { fromDate, toDate } = queryData;
    let queryParams;

    const dashBoard = async () => {

        if (fromDate) queryParams += `fromDate=${fromDate}`
        if (toDate) queryParams += `toDate=${toDate}`


        if (queryParams && queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const endPoint = queryParams ? `${DASHBOARD_API}?${queryParams}` : DASHBOARD_API

        try {
            const resp = await customAxios.get(endPoint);
            return resp.data;
        } catch (error) {
            console.log("Something Wrong: ", error)
            throw error;
        }

    }


    const queryKey = ["getDashboard"]
    if (fromDate) queryKey.push(`fromDate=${fromDate}`)
    if (toDate) queryKey.push(`toDate=${toDate}`)

    return useQuery({
        queryKey: queryKey.length > 1 ? queryKey : ["getDashboard"],
        queryFn: dashBoard,
        onError: (err) => {
            console.log("err : ", err)
        }
    })
}

// DELETE ALL ASSIGN SHIFT 
export const useDeleteAllAssignShift = ({ onSuccess, onError }) => {

    const deleteAllAssign = async (body) => {


        const resp = await customAxios.delete(`${URL_ASSIGN}/delete`, {
            data: body
        })

        return resp.data;
    }

    return useMutation({
        mutationFn: deleteAllAssign,
        onSuccess,
        onError
    })
}

