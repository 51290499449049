import React from "react";
import PropTypes from "prop-types";

function TimeCircle({ className }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8228 9.50039C16.8228 13.545 13.5445 16.8233 9.49992 16.8233C5.45529 16.8233 2.177 13.545 2.177 9.50039C2.177 5.45577 5.45529 2.17747 9.49992 2.17747C13.5445 2.17747 16.8228 5.45577 16.8228 9.50039Z"
        // stroke="#A3A3AB"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2165 11.8298L9.23193 10.0493V6.21213"
        // stroke="#A3A3AB"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

TimeCircle.propTypes = {
  className: PropTypes.string,
};

TimeCircle.defaultProps = {
  className: "text-[#A3A3AB]",
};

export default TimeCircle;
