import React from "react";

function FiFilePlus() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83329 1.33301H4.49996C4.14634 1.33301 3.8072 1.47348 3.55715 1.72353C3.3071 1.97358 3.16663 2.31272 3.16663 2.66634V13.333C3.16663 13.6866 3.3071 14.0258 3.55715 14.2758C3.8072 14.5259 4.14634 14.6663 4.49996 14.6663H12.5C12.8536 14.6663 13.1927 14.5259 13.4428 14.2758C13.6928 14.0258 13.8333 13.6866 13.8333 13.333V5.33301L9.83329 1.33301Z"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12V8"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 10H10.5"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83337 1.33301V5.33301H13.8334"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FiFilePlus;
