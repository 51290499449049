import customAxios from "utils/axios";
import { useQuery, useMutation } from '@tanstack/react-query'

const API = `${process.env.REACT_APP_API_SHIFT}/api/assignshiftrule/autoassign`

export const useAutoAssign = ({ onSuccess, onError }) => {
    const autoAssign = async (body) => {
        const resp = await customAxios.post(`${API}`, body)
        return resp.data;
    }

    return useMutation({
        mutationFn: autoAssign,
        onSuccess,
        onError
    })
}

export const useGetAllAutoAssign = () => {
    const allAutoAssign = async () => {
        const resp = await customAxios.get(`${API}`)
        return resp.data;
    }

    return useQuery({
        queryKey: ["allAutoAssign"],
        queryFn: allAutoAssign,
        onError: (err) => {
            alert("Something Wrong!")
        }
    })
}