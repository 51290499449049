import React from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";

import { useUpdateShift } from "services/shiftServices";
import { useSelector } from "react-redux";
import { alertSuccess, alertError } from "services/alert";

import { FiTrashNoBold, FiSaveSvg } from "assets/icons";
import FormShift from "shared/formShift/formShift";
import { detailShiftSchema } from "pages/dashboard/shiftDetailSchema";

function ModalShiftDetails({
  setShowModalDelete,
  setModalEditShift,
  shiftDetailData,
  shiftDetailId,
}) {
  const queryClient = useQueryClient();

  const { currentRole } = useSelector((state) => state.loginSlice);

  const initialValue = {
    shiftName: "",
    color: "red",
    startTime: "",
    endTime: "",

    startBreakTime: "",
    endBreakTime: "",

    // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
    repeatDate: [],
    repeatBy: {
      repeatType: "",
      repeatValue: [],
    },
    locationId: "",

    employees: [
      {
        employeesNeeded: 0,
        jobPosition: "",
      },
    ],
  };

  // SET TO API
  const setToApi = {
    ...initialValue,
    shiftName: shiftDetailData ? shiftDetailData.shiftName : "",
    color: shiftDetailData ? shiftDetailData.color : "",
    startTime: shiftDetailData ? shiftDetailData.startTime : "",
    locationId: shiftDetailData ? shiftDetailData.locationId : "",
    endTime: shiftDetailData ? shiftDetailData.endTime : "",
    startBreakTime: shiftDetailData ? shiftDetailData.startBreakTime : "",
    endBreakTime: shiftDetailData ? shiftDetailData.endBreakTime : "",
    repeatDate: shiftDetailData ? shiftDetailData.repeatDate : [],
    repeatBy: {
      repeatType: shiftDetailData ? shiftDetailData.repeatBy.repeatType : "",
      repeatValue: shiftDetailData ? shiftDetailData.repeatBy.repeatValue : "",
    },
    employees: shiftDetailData ? shiftDetailData.employees : [],
  };

  // UPDATE SHIFT DETAIL
  const { mutate: updateShiftDetail, isLoading: loadingUpdateShift } =
    useUpdateShift(shiftDetailId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllShift");
        alertSuccess("Data Success Updated");
        setModalEditShift(false);
      },
      onError: (err) => {
        alertError("Something Wrong");
        console.log("something wrong", err);
      },
    });

  if (loadingUpdateShift) {
    return <div>Loading...</div>;
  }

  return (
    <ModalDialog
      icon={[
        {
          icon: (
            <div className="flex gap-3 items-center mr-2">
              {currentRole === "Admin" && (
                <FiTrashNoBold
                  onClick={() => {
                    setShowModalDelete(true);
                  }}
                />
              )}
            </div>
          ),
        },
      ]}
      title={"Shift Details"}
      className={"h-[700px] w-[750px] "}
      onClose={() => setModalEditShift(false)}
    >
      <FormShift
        initialValue={setToApi}
        validationSchema={detailShiftSchema}
        handleSubmit={(val) => {
          const allVal = {
            ...val,
            repeatDate: {
              from: val.repeatDate[0],
              to: val.repeatDate[val.repeatDate.length - 1],
            },
          };

          updateShiftDetail(allVal);
        }}
        nameButton={"Update Shift"}
        iconButton={<FiSaveSvg />}
      />
    </ModalDialog>
  );
}

export default ModalShiftDetails;
