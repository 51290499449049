import React, { useState, useContext, useEffect } from "react";
import { useGetAllConflickData } from "services/conflickDataApi";
import { Tabs } from "@bluesilodev/timhutcomponents";
// import Tabs from "coba/tabsMenu/tabMenu";
// import TabOverlapping from "shared/showConflick/tabOverlapping";
import { formatDate } from "utils/common/generateDateRange";
import ShiftReplacementAll from "./shiftReplacementAll";
import { formatDateIndEng } from "utils/common/generateDateRange";
function ButtonTab({ children, setMenuTab, value }) {
  return (
    <button
      onClick={() => setMenuTab(value)}
      className="w-full font-semibold focus:bg-secondary focus:text-white p-2 rounded-md bg-gray-300 text-gray-500 "
    >
      {children}
    </button>
  );
}
function ConflickAllData({ allDate }) {
  const fromDate = formatDate(allDate[0]);
  const toDate = formatDate(allDate[allDate.length - 1]);
  const [menuTab, setMenuTab] = useState(1);

  const {
    data: conflickData,
    isLoading: loadingConflick,
    isSuccess,
  } = useGetAllConflickData({
    fromDate,
    toDate,
  });

  if (loadingConflick) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full">
      {/* <Tabs
        options={[
          {
            value: <div className="w-full">Overlapping Shift</div>,
            id: 1,
          },
          {
            value: <div className="w-full">Shift Replacements</div>,
            id: 2,
          },
          {
            value: <div className="w-full">Exceeding Time</div>,
            id: 3,
          },
        ]}
        value={menuTab}
        onClick={(val) => {
          setMenuTab(val);
        }}
      /> */}
      <div className="grid grid-cols-3 w-full text-white gap-2 border-2 border-gray-400 p-2 rounded-lg">
        <ButtonTab setMenuTab={setMenuTab} value={1}>
          Overlapping Shift
        </ButtonTab>
        <ButtonTab setMenuTab={setMenuTab} value={2}>
          Shift Replacements
        </ButtonTab>
        <ButtonTab setMenuTab={setMenuTab} value={3}>
          Exceeding Time
        </ButtonTab>
      </div>
      <div>
        <div>
          {menuTab === 1 && (
            <div className="mt-5 flex flex-col gap-3 ">
              {conflickData &&
                conflickData.conflicts.length > 0 &&
                conflickData.conflicts.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="border-[1px] border-gray-400 flex flex-col gap-3 p-3 rounded-md cursor-pointer"
                    >
                      <div>
                        <h1 className="text-secondary">
                          {item.conflictingAssignShifts.length} Overlapping
                          Shifts on {formatDateIndEng(item.date)}
                        </h1>
                        <p className="font-semibold">
                          {item.employeeData.locationName} {">"}{" "}
                          {item.employeeData.firstName}
                        </p>
                      </div>

                      <div className="flex gap-3">
                        {item.conflictingAssignShifts.length > 0 &&
                          item.conflictingAssignShifts.map(
                            (conflict, index) => {
                              const confllickDetail = conflict.shiftDetails;

                              return (
                                <div
                                  key={index}
                                  className="border-[1px] border-gray-400 p-1"
                                >
                                  <div
                                    style={{
                                      backgroundColor: confllickDetail.color,
                                    }}
                                    className=" flex flex-col border-[1px] h-[60px] w-[200px] justify-center items-center rounded-md"
                                  >
                                    <h1>{conflict.jobPosition}</h1>
                                    <p>
                                      {confllickDetail.startTime} -{" "}
                                      {confllickDetail.endTime}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div>{menuTab === 2 && <ShiftReplacementAll />}</div>

        <div>{menuTab === 3 && <div>-</div>}</div>
      </div>
    </div>
  );
}

export default ConflickAllData;
